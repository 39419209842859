import React from "react";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import {divIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import {FaMapMarkerAlt} from "react-icons/all";

export const MapImageLocationStore = ({lat, lng}: any) => {

    const ChangeView = ({center, zoom}: any) => {
        const map = useMap();
        map.setView(center, zoom);
        return null;
    }

    const icon = renderToStaticMarkup(<FaMapMarkerAlt color={'#1b73f4'} size={35}/>);

    return (
        <MapContainer
            center={[lat, lng]}
            zoom={14}
            style={{height: '100%'}}
            scrollWheelZoom={false}
            zoomControl={false}
            doubleClickZoom={false}
            closePopupOnClick={false}
            dragging={false}
            zoomSnap={undefined}
            zoomDelta={undefined}
            trackResize={false}
            touchZoom={false}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <ChangeView center={[lat, lng]}/>
            <Marker position={[lat, lng]} icon={divIcon({html: icon, iconAnchor: [-6, 36]})}/>
        </MapContainer>
    )
}