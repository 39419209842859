import {Button, Form, Input, Switch} from "antd-mobile";
import {useDispatch, useSelector} from "../../hooks";
import {authActions} from "../../../modules/auth/authSlice";

export const FormDeliveryFree = () => {
    const {profile, loading} = useSelector(({auth}) => auth);
    const dispatch = useDispatch();
    const {actionGetEditStore} = authActions;
    const {store}: any = profile;

    const onFinish = (values: any) => {
        if (values) {
            dispatch(actionGetEditStore({data: values, slug: store.id}));
        }
    }

    return (
        <Form initialValues={store} onFinish={onFinish}>
            <Form.Item label='ارسال رایگان' name="free_delivery" valuePropName="checked">
                <Switch/>
            </Form.Item>
            <Form.Item label='حداقل مبلغ برای ارسال رایگان' name="min_price_for_free_delivery">
                <Input type="number" placeholder="مبلغ مورد نظر را وارد کنید"/>
            </Form.Item>
            <Button type="submit" color="warning" block size="mini" loading={loading.editStore}>ویرایش</Button>
        </Form>
    )
}