import {Switch, useRouteMatch} from "react-router";
import {Redirect, Route} from "react-router-dom";
import {ProductsPage} from "./ProductsPage";
import React from "react";
import {ManageProductPage} from "./ManageProductPage";

export const ProductRouters = () => {
    const {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/:slug/manage/:type`} component={ManageProductPage}/>
            <Route path={`${path}/:source`} component={ProductsPage}/>
            <Redirect to='/products/add'/>
        </Switch>
    )
}