import {main} from "../../core/apis";
import {Apis, BASES_API} from "../../core/apis";

/*Auth*/
export const apiFetchCsrfCookie = () => {
    return main.get(BASES_API.URL_SERVER + Apis.SANCTUM_CSRF_COOKIE_GET);
}

export const apiLoginByMobile = (mobile: string) => {
    return main.post(Apis.LOGIN, {mobile});
}

export const apiActiveCode = (mobile: string, code: string) => {
    return main.post(Apis.LOGIN_ACTIVE_POST, {mobile, code});
}

export const apiCheckedLogin = () => {
    return main.get(Apis.PROFILE);
}

export const apiEditStore = (slug: string, data: any) => {
    return main.put(`stores/${slug}`, data);
}

export const apiResendCode = (mobile: string) => {
    return main.post(Apis.RESEND_ACTIVE_CODE_POST, {mobile});
}

export const apiLoggedOut = () => {
    return main.post(Apis.LOGGED_OUT);
}