import {Tabs} from "antd-mobile";
import {ListStoreProducts} from "../../core/components/List";
import {ProductCategoriesPage} from "./ProductCategoriesPage";
import {Switch, useRouteMatch} from "react-router";
import {Redirect, Route, useHistory} from "react-router-dom";
import {ProductCategoryPage} from "./ProductCategoryPage";

export const ProductsPage = () => {
    const {url, path}: any = useRouteMatch();
    const {push} = useHistory();
    return (
        <Tabs
            className='sticky-tab'
            defaultActiveKey={url}
            onChange={(key) => {
                push(key);
            }}
        >
            <Tabs.TabPane title="افزودن" key={`/products/add`}>
                <Switch>
                    <Route exact path={`${path}/product-categories`} component={ProductCategoriesPage}/>
                    <Route path={`${path}/product-categories/:id/products`} component={ProductCategoryPage}/>
                    <Redirect to={"/products/add/product-categories"}/>
                </Switch>
            </Tabs.TabPane>
            <Tabs.TabPane title="موجود" key={`/products/available`}>
                <ListStoreProducts/>
            </Tabs.TabPane>
            <Tabs.TabPane title="تمام شده" key={`/products/finished`}>
                <ListStoreProducts type="finished"/>
            </Tabs.TabPane>
        </Tabs>
    )
}