import axios from "axios";

export enum URL_SERVERS {
    PRODUCT = 'https://panel.omato.ir/',
    DEVELOP = 'http://localhost:8000/'
}

export const BASE_URL_SITE = window.location.hostname.search('omato.ir') !== -1 ? URL_SERVERS.PRODUCT : URL_SERVERS.DEVELOP;

export enum BASES_API {
    URL_SERVER = 'https://panel.omato.ir/',
    VERSION_API = 'api/supplier/v1/',
}

export enum Apis {
    SANCTUM_CSRF_COOKIE_GET = 'sanctum/csrf-cookie',
    LOGIN = 'login',
    LOGIN_ACTIVE_POST = 'active',
    RESEND_ACTIVE_CODE_POST = 'resend-active-code',
    PROFILE = 'profile',
    LOGGED_OUT = 'logout',
    LIST_ORDERS = 'orders',
    UPDATE_ORDERS = 'orders',
    SHOW_ORDER = 'orders',
    PRODUCT_CATEGORIES_GET = 'productCategories',
    PRODUCTS_GET = 'products',
    ORDER_COMMENTS_GET = 'orderComments',
    STORE_PRODUCTS_GET = 'storeProducts',
}

/*BASE AXIOS MAIN*/
export const main = axios.create({
    baseURL: BASE_URL_SITE + BASES_API.VERSION_API,
    withCredentials: true
});