import {useDispatch, useSelector} from "../../core/hooks";
import {useEffect} from "react";
import {productsActions} from "./productsSlice";
import {useRouteMatch} from "react-router";
import {ErrorBlock, Image, List} from "antd-mobile";
import _ from "lodash";
import {LoadingPage} from "../../core/components/Loader";
import {useHistory} from "react-router-dom";

export const ProductCategoryPage = () => {
    const {loader_get_products_login, products}: any = useSelector(state => state.products);
    const dispatch = useDispatch();
    const {push} = useHistory();
    let match: any = useRouteMatch();
    const {getProductCategoryProducts, setProductCategoryProducts} = productsActions;
    const {id: productCategory} = match.params;

    useEffect(() => {
        dispatch(getProductCategoryProducts(productCategory));
        // eslint-disable-next-line
    }, []);

    const handleClickItem = (slug: string) => {
        push(`/products/${slug}/manage/create`);
    }

    useEffect(() => {
        return () => {
            dispatch(setProductCategoryProducts([]));
        }
        // eslint-disable-next-line
    }, []);

    return !loader_get_products_login ? (
        <>
            {products.length ?
                <List>
                    {_.map(products, category => (
                        <List.Item
                            key={category.id}
                            prefix={<Image src={category.image} style={{borderRadius: 20}} fit='cover' width={40}
                                           height={40}/>}
                            onClick={() => handleClickItem(category.slug)}
                        >
                            {category.title}
                        </List.Item>
                    ))}
                </List>
                : <ErrorBlock status='empty' title="404" description="محصولی یافت نشد."/>
            }
        </>
    ) : (
        <LoadingPage/>
    )
}