import {Route, Redirect} from "react-router-dom";
import {toast} from "react-toastify";
import {useSelector} from "../../hooks";

export const PrivateRoute = ({children = null, component: Component = null, ...rest}: any) => {

    const {isAuthorized} = useSelector(state => state.auth);

    const render = (props: any) => {
        if (isAuthorized) {
            if (rest.render) {
                return rest.render(props);
            } else {
                return Component ? <Component {...props}/> : children;
            }
        } else {
            toast.error('لطفا ابتدا وارد حساب کاربری خود شوید');
            return <Redirect to={{pathname: "/auth/login", state: {from: props.location}}}/>;
        }
    }

    return <Route {...rest} render={render}/>;
}
