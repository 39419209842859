import React, {useState} from "react";
import {TabBar} from "antd-mobile";
import {AppstoreOutline, UnorderedListOutline, UserOutline} from 'antd-mobile-icons';
import _ from "lodash";
import {useHistory} from "react-router";
import {FaComments} from "react-icons/all";

export const Footer = () => {
    const history = useHistory();
    const tabs = [
        {
            key: '/profile',
            title: 'حساب کاربری',
            icon: <UserOutline/>
        },
        {
            key: '/orders',
            title: 'سفارشات',
            icon: <UnorderedListOutline/>,
            // badge: '5',
        },
        {
            key: '/products',
            title: 'محصولات',
            icon: <AppstoreOutline/>,
        },
        {
            key: '/comments',
            title: 'نظرات',
            icon: <FaComments/>,
        },
    ];
    const [activeKey, setActiveKey] = useState(history.location.pathname);

    const handleChangeTabBar = (key: string) => {
        setActiveKey(key);
        history.push(`${key}`);
    }

    return (
        <div style={{position: 'fixed', right: 0, left: 0, bottom: 0,background:'#fff'}}>
            <TabBar
                onChange={handleChangeTabBar}
                activeKey={_.find(tabs, ({key}) => history.location.pathname.startsWith(key))?.key || activeKey}
            >
                {_.map(tabs, tab => (
                    <TabBar.Item
                        key={tab.key}
                        icon={tab.icon}
                        title={tab.title}
                        // badge={tab.badge}
                    />
                ))}
            </TabBar>
        </div>
    )
}