import {Form, Image, Input, Selector} from "antd-mobile";

export const  FormSupplier = ({data,owner}:any) =>{
    return (
        <>
            <div style={{textAlign: 'center'}}>
                <Image src={data.avatar} width={64} height={64} fit='cover' style={{borderRadius: 32}}/>
                <div><small>{owner && 'مالک فروشگاه'}</small></div>
            </div>
            <Form initialValues={data}>
                <Form.Item label='نام' name="first_name">
                    <Input type="text" disabled/>
                </Form.Item>
                <Form.Item label='نام خانوادگی' name="last_name">
                    <Input type="text" disabled/>
                </Form.Item>
                <Form.Item label='موبایل' name="mobile">
                    <Input type="tel" disabled/>
                </Form.Item>
                <Form.Item label='ایمیل' name="email">
                    <Input type="email" disabled/>
                </Form.Item>
                <Form.Item label='کد ملی' name="national_code">
                    <Input type="text" disabled/>
                </Form.Item>
                <Form.Item label='شماره کارت' name="card_number">
                    <Input type="text" disabled/>
                </Form.Item>
                <Form.Item label='شماره شبا' name="shaba_number">
                    <Input type="text" disabled/>
                </Form.Item>
                <Form.Item name='gender' label='جنسیت'>
                    <Selector
                        disabled
                        options={[{label: 'مرد', value: 'مرد'}, {label: 'زن', value: 'زن'}]}
                        columns={2}
                    />
                </Form.Item>
                <Form.Item name='born' label='تولد'>
                    <Input type="date" disabled/>
                </Form.Item>
            </Form>
        </>
    )
}