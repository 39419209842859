import React from "react";
import {FaBars, FaExclamationCircle} from "react-icons/all";
import {Link} from "react-router-dom";
import {Dialog, NavBar, Switch} from "antd-mobile";
import {useDispatch, useSelector} from "../../hooks";
import {authActions} from "../../../modules/auth/authSlice";

export const Header = ({changeSider}: any) => {
    const {profile, loading} = useSelector(({auth}) => auth);
    const dispatch = useDispatch();
    const {actionGetEditStore} = authActions;
    const {store}: any = profile;
    const handleChangeStore = () => {
        Dialog.confirm({
            content: 'میخواهید وضعیت فروشگاه را تغییر دهید؟',
            cancelText: 'خیر',
            confirmText: 'بله',
            onConfirm: async () => {
                dispatch(actionGetEditStore({data: {open: !store.open}, slug: store.id}));
            },
        })
    }
    const right = (
        <>
            {store ?
                <Switch uncheckedText='بسته' checkedText='باز' checked={store.open} onChange={handleChangeStore}
                        loading={loading.editStore}/> :
                <FaExclamationCircle/>}
        </>
    )

    return (
        <NavBar
            backArrow={<FaBars/>}
            onBack={() => changeSider((oldState: boolean) => !oldState)}
            right={right}
        >
            <Link to='/' className='base-children-part-header'>OMATO</Link>
        </NavBar>
    )
}