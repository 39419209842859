import {ErrorBlock, List} from "antd-mobile";
import _ from "lodash";
import {useDispatch, useSelector} from "../../../core/hooks";
import {useEffect} from "react";
import {actionGetOrders, actionSetOrders} from "../ordersSagas";
import {LoadingPage} from "../../../core/components/Loader";
import {SliderProductsOrders} from "../../../core/components/Slider";

export const ListOrders = ({type}: any) => {
    const {orders, loading} = useSelector(({orders}) => orders);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actionGetOrders({type}));

        return () => {
            dispatch(actionSetOrders({orders: []}));
        }
        // eslint-disable-next-line
    }, []);


    return !loading.getOrders ? (
        <>
            {orders.length ?
                <List>
                    {_.map(orders, (order: any, index) => (
                        <List.Item key={index} title={`سفارش شماره ${order.order_id}`}>
                            <SliderProductsOrders products={order?.products} showTitle={true}/>
                        </List.Item>
                    ))}
                </List>
                :
                <ErrorBlock status='empty' title="404" description="سفارشی یافت نشده."/>
            }
        </>
    ) : <LoadingPage/>
}