import {combineReducers} from "redux";
import {authReducer} from "../../../modules/auth/authSlice";
import {productsReducer} from "../../../modules/products/productsSlice";
import {commentsReducer} from "../../../modules/comments/commentsSlice";
import {storeProductsReducer} from "../../../modules/products/storeProducts/storeProductsSlice";
import {ordersReducer} from "../../../modules/orders/ordersSlice";

const reducers = combineReducers({
    auth: authReducer,
    products: productsReducer,
    comments: commentsReducer,
    storeProducts: storeProductsReducer,
    orders: ordersReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;