import {toast} from "react-toastify";
import {call, put, delay, fork} from 'redux-saga/effects';
import axios, {AxiosResponse} from "axios";
import {authActions} from "./authSlice";
import * as authApis from "./auth.api";
import {notifyErrorResponseApi} from "../../core/errors";
import {Toast} from "antd-mobile";

const {setProfile, changeValueFormLogin, loggedIn, actionSetEditStore, actionChangeLoadingEditStore} = authActions;
const {actionChangeLoadingLogin} = authActions;
const {apiActiveCode, apiCheckedLogin, apiFetchCsrfCookie, apiLoginByMobile, apiResendCode, apiLoggedOut} = authApis;
const {apiEditStore} = authApis;

const fetchCsrfCookie = async () => {
    return await apiFetchCsrfCookie();
}

function* startTimerSaga({timer}: any) {
    while (timer > 0) {
        yield delay(1000);
        yield put(changeValueFormLogin({name: 'timer', value: --timer}));
    }
}

export function* loginByMobile({payload: {mobile, history}}: any) {
    try {
        const responseCsrf: AxiosResponse = yield call(fetchCsrfCookie);
        if (responseCsrf.status === 204) {
            const {status, data}: AxiosResponse = yield call(apiLoginByMobile, mobile);
            if (status === 200) {
                toast.success(data.message);
                yield put(changeValueFormLogin({name: 'page', value: 'OTP_VALIDATION'}));
                yield fork(startTimerSaga, {timer: data.resend_at});
                history.push({pathname: '/auth/active-code', state: {from: history?.location?.state?.from}});
            }
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    } finally {
        yield put(changeValueFormLogin({name: 'loader_by_mobile', value: false}));
    }
}

export function* activeLogin({payload: {mobile, code, history}}: any) {
    try {
        const response: AxiosResponse = yield call(apiActiveCode, mobile, code);
        const {status, data} = response;
        if (status === 200) {
            toast.success(data.message);
            yield put(loggedIn(data.profile));
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    } finally {
        yield put(changeValueFormLogin({name: 'loader_otp_validation', value: false}));
    }
}

export function* resendCode({payload: {mobile}}: any) {
    try {
        const response: AxiosResponse = yield call(apiResendCode, mobile);
        const {status, data} = response;
        if (status === 200) {
            toast.success(data.message);
            yield fork(startTimerSaga, {timer: data.resend_at});
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    } finally {
        yield put(changeValueFormLogin({name: 'loader_otp_validation', value: false}));
    }
}

export function* profile() {
    try {
        const {status, data}: AxiosResponse = yield call(apiCheckedLogin);
        if (status === 200) {
            yield put(setProfile(data.profile));
        } /*else {
            yield put(loggedOut());
        }*/
    } catch (e: any) {
        // yield put(loggedOut());
        if (axios.isAxiosError(e) && e?.response?.status !== 401) {
            notifyErrorResponseApi(e);
        }
    }
    finally {
        yield put(actionChangeLoadingLogin(false));
    }
}

export function* sagaEditStore({payload}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiEditStore, payload.slug, payload.data);
        if (status === 200) {
            Toast.show({content: data.message, position: 'bottom'});
            yield put(actionSetEditStore(data.profile));
        } else {
            Toast.show({content: 'لطفا مجددا تلاش کنید', position: 'bottom'});
        }
    } catch (e) {
        Toast.show({content: 'لطفا مجددا تلاش کنید', position: 'bottom'});
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    } finally {
        yield put(actionChangeLoadingEditStore(false));
    }
}

export function* loggedOutSaga() {
    try {
        const {status, data}: AxiosResponse = yield call(apiLoggedOut);
        if (status === 200) {
            toast.success(data.message);
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}