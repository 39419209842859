import {Spin} from "antd";
import React from "react";

export const LoadingPage = () => {
    return (
        <div
            style={{
                height:'100vh',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                color:'#1b73f4'
            }}
        >
            <Spin tip="در حال دریافت اطلاعات..."/>
        </div>
    )
}