import {ErrorBlock, List, Rate} from "antd-mobile";
import {useDispatch, useSelector} from "../../core/hooks";
import _ from "lodash";
import {useEffect} from "react";
import {commentsActions} from "./commentsSlice";
import {LoadingPage} from "../../core/components/Loader";

export const CommentsPage = () => {
    const {order_comments, loader_get_comments}: any = useSelector(state => state.comments);
    const dispatch = useDispatch();
    const {getOrderComments, setOrderComments} = commentsActions;

    useEffect(() => {
        dispatch(getOrderComments());
        return () => {
            dispatch(setOrderComments([]));
        }
        // eslint-disable-next-line
    }, []);
    return !loader_get_comments ? (
        <div>
            <List mode='card'>
                {order_comments.length ? _.map(order_comments, comment => (
                        <List.Item key={comment.id} title={`شماره فاکتور ${comment.order_store_id}`}>
                            <div style={{textAlign: 'center'}}>
                                <div dir="ltr">
                                    <Rate allowHalf value={comment.score}/>
                                </div>
                            </div>
                            <p>{comment.message}</p>
                        </List.Item>
                    )) :
                    <ErrorBlock status='empty' title="404" description="کامنتی یافت نشد."/>
                }
            </List>
        </div>
    ) : (<LoadingPage/>)
}