import axios, {AxiosResponse} from "axios";
import {call, put, takeEvery} from "redux-saga/effects";
import {apiGetOrders} from "./ordersAPI";
import {notifyErrorResponseApi} from "../../core/errors";
import {ordersActions} from "./ordersSlice";

export const {actionGetOrders, actionSetOrders} = ordersActions;

export function* sagaGetOrders({payload: {type, length, page}}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiGetOrders, type, length, page);
        if (status === 200) {
            yield put(actionSetOrders({orders: data.orders,length:data.length}));
        } else {
            yield put(actionSetOrders({orders: []}));
        }
    } catch (e) {
        yield put(actionSetOrders({orders: []}));
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}

export const sagaOrdersRoot = function* () {
    yield takeEvery(actionGetOrders.type, sagaGetOrders);
}