import {takeEvery,all} from 'redux-saga/effects';
import {authActions} from "../../../modules/auth/authSlice";
import {authSagas} from "../../../modules/auth";
import {productsActions} from "../../../modules/products/productsSlice";
import {commentsActions} from "../../../modules/comments/commentsSlice";
import {sagaProducts} from "../../../modules/products/productsSagas";
import * as commentsSagas from "../../../modules/comments/comments.sagas";
import * as storeProductsSagas from "../../../modules/products/storeProducts/storeProducts.sagas";
import {storeProductsActions} from "../../../modules/products/storeProducts/storeProductsSlice";
import {sagaOrdersRoot} from "../../../modules/orders/ordersSagas";
// import {ordersActions} from "../../../modules/orders/OrdersSlice";
// import {ordersSagas} from "../../../modules/orders";

export function* sagas() {
    /*Auth*/
    const {getActiveCodeLogin, getProfile, validationActiveCodeLogin, getResendCode, loggedOut,actionGetEditStore} = authActions;
    const {activeLogin, loginByMobile, profile, resendCode, loggedOutSaga,sagaEditStore} = authSagas;
    yield takeEvery(getProfile.type, profile);
    yield takeEvery(getActiveCodeLogin.type, loginByMobile);
    yield takeEvery(validationActiveCodeLogin.type, activeLogin);
    yield takeEvery(getResendCode.type, resendCode);
    yield takeEvery(loggedOut.type, loggedOutSaga);
    yield takeEvery(actionGetEditStore.type, sagaEditStore);

    /*ProductCategories*/
    const {getProductCategories, getProductCategoryProducts,actionGetProduct,actionGetCreateProduct} = productsActions;
    const {actionGetEditProduct} = productsActions;
    const {getProductCategoriesSaga, getProductCategoriesProductsSaga,sagaGetProduct,sagaCreateProduct} = sagaProducts;
    const {sagaEditProduct} = sagaProducts;
    yield takeEvery(getProductCategories.type, getProductCategoriesSaga);
    yield takeEvery(getProductCategoryProducts.type, getProductCategoriesProductsSaga);
    yield takeEvery(actionGetProduct.type, sagaGetProduct);
    yield takeEvery(actionGetCreateProduct.type, sagaCreateProduct);
    yield takeEvery(actionGetEditProduct.type, sagaEditProduct);


    /*ProductCategories*/
    const {getOrderComments} = commentsActions;
    const {getOrderCommentsSaga} = commentsSagas;
    yield takeEvery(getOrderComments.type, getOrderCommentsSaga);

    /*storeProducts*/
    const {getStoreProducts} = storeProductsActions;
    const {getStoreProductsSaga} = storeProductsSagas;
    yield takeEvery(getStoreProducts.type, getStoreProductsSaga);


}

export default function* rootSaga() {
    yield all([sagas(), sagaOrdersRoot()])
}