import React, {useState} from "react";
import {Switch} from "react-router";
import {Redirect, Route} from "react-router-dom";
import {Header, Footer, Sidebar} from "../components";
import {Layout} from "antd";
import {OrdersPage} from "../../modules/orders";
import {Mask} from "antd-mobile";
import {Error404, NotFoundStore} from "../errors";
import {StoreRoute} from "./CustomRoute/StoreRoute";
import {CommentsPage} from "../../modules/comments";
import {ProfilePage} from "../../modules/profile";
import {ProductRouters} from "../../modules/products/ProductRouters";

export const PanelRouters = () => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <>
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed}/>
            <Mask visible={!collapsed} onMaskClick={() => setCollapsed(true)}/>
            <Layout>
                <Layout.Header>
                    <Header changeSider={setCollapsed}/>
                </Layout.Header>
                <Layout.Content style={{position: 'relative', marginBottom: 50}}>
                    <div style={{position: "absolute", top: 0, bottom: 0, right: 0, left: 0, overflow: "auto"}}>
                        <Switch>
                            <Route path='/profile' component={ProfilePage}/>
                            <StoreRoute path='/orders' component={OrdersPage}/>
                            <StoreRoute path='/products' component={ProductRouters}/>
                            <StoreRoute path='/comments' component={CommentsPage}/>
                            <Route path='/notfound/store' component={NotFoundStore}/>
                            <Redirect exact from='/' to='/profile'/>
                            <Route path="*" component={Error404}/>
                        </Switch>
                    </div>
                </Layout.Content>
                <Layout.Footer>
                    <Footer/>
                </Layout.Footer>
            </Layout>
        </>
    );
}