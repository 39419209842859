import {Card, ErrorBlock, Tabs} from "antd-mobile";
import {useSelector} from "../../core/hooks";
import {FormDeliveryFree, FormStore, FormSupplier, FormWallet} from "../../core/components/Forms";

export const ProfilePage = () => {
    const {profile} = useSelector(state => state.auth);
    const {store,wallet, owner} = profile;
    return (
        <Tabs className='sticky-tab'>
            <Tabs.TabPane title='پروفایل' key='/user'>
                <Card>
                    <FormSupplier data={profile} owner={owner}/>
                </Card>
            </Tabs.TabPane>
            <Tabs.TabPane title='فروشگاه' key='/store'>
                {store
                    ?
                    <FormStore data={store}/>
                    :
                    <ErrorBlock status='empty' title="404" description="فروشگاهی یافت نشد."/>
                }
            </Tabs.TabPane>
            <Tabs.TabPane title='ارسال رایگان' key='/delivery-free'>
                {store
                    ?
                    <FormDeliveryFree data={store}/>
                    :
                    <ErrorBlock status='empty' title="404" description="فروشگاهی یافت نشد."/>
                }
            </Tabs.TabPane>
            <Tabs.TabPane title='کیف پول' key='/wallet'>
                {wallet
                    ?
                    <FormWallet data={wallet}/>
                    :
                    <ErrorBlock status='empty' title="404" description="کیف پول یافت نشد."/>
                }
            </Tabs.TabPane>
        </Tabs>
    )
}
