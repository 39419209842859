import {Button, Form, Image, Input} from "antd-mobile";
import {useParams} from "react-router";
import {useEffect} from "react";
import {useDispatch, useSelector} from "../../core/hooks";
import {productsActions} from "./productsSlice";
import {LoadingPage} from "../../core/components/Loader";
import {useHistory} from "react-router-dom";

export const ManageProductPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const {slug, type}: any = params;
    const {actionGetProduct, actionGetCreateProduct, actionGetEditProduct} = productsActions;
    const {product, loader_get_product, loading}: any = useSelector(state => state.products);

    useEffect(() => {
        dispatch(actionGetProduct(slug));
        // eslint-disable-next-line
    }, [slug]);

    const onFinish = (values: any) => {
        if (type === "create") {
            dispatch(actionGetCreateProduct({data: values, history}));
        } else {
            dispatch(actionGetEditProduct({data: values, slug: product.store_product_id, history}));
        }
    }
    return !loader_get_product ? (
        <div>
            <Form
                onFinish={onFinish}
                initialValues={{...product, product_id: product.product_id || product.id}}
                footer={
                    <Button block type='submit' color='success' size="mini" loading={loading.manageProduct}>
                        ذخیره
                    </Button>
                }
            >
                <div style={{textAlign: 'center'}}>
                    <Image src={product.image} width={64} height={64} fit='cover' style={{borderRadius: 32}}/>
                </div>
                <Form.Item name="title" label='نام محصول' disabled children={<Input/>}/>
                <Form.Item name="product_id" disabled children={<Input/>} style={{display: "none"}}/>
                <Form.Item name="store_product_id" disabled children={<Input/>} style={{display: "none"}}/>
                <Form.Item
                    name='price'
                    label='قیمت محصول (به تومان)'
                    rules={[{required: true, message: 'لطفا مبلغ محصول را وارد کنید'}]}
                >
                    <Input placeholder='مبلغ را وارد کنید'/>
                </Form.Item>
                <Form.Item name='discount' label='تخفیف (به تومان)'>
                    <Input placeholder='مبلغ را وارد کنید'/>
                </Form.Item>
                <Form.Item
                    name='inventory'
                    label='تعداد موجود'
                    rules={[{required: true, message: 'لطفا موجودی محصول را وارد کنید'}]}
                >
                    <Input placeholder='مقدار را وارد کنید'/>
                </Form.Item>
            </Form>
        </div>
    ) : (<LoadingPage/>)
}