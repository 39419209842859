import {BrowserRouter} from "react-router-dom";
import React from "react";
import {Switch} from "react-router";
import {AuthRouters} from "./AuthRouters";
import {PanelRouters} from "./PanelRouters";
import {Layout} from "antd";
import {AuthRoute, PrivateRoute} from "./CustomRoute";

export const Router = () => {

    return (
        <BrowserRouter>
            <Layout style={{height: "100vh"}}>
                <Switch>
                    <AuthRoute path='/auth' component={AuthRouters}/>
                    <PrivateRoute path='/' component={PanelRouters}/>
                </Switch>
            </Layout>
        </BrowserRouter>
    )
}