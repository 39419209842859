import {Route, Redirect} from "react-router-dom";
import {useSelector} from "../../hooks";

export const StoreRoute = ({children, component: Component, ...rest}: any) => {
    const {profile}: any = useSelector(state => state.auth);
    const render = (props: any) => {
        if (profile.store?.id) {
            return Component ? <Component {...props}/> : children;
        } else {
            return <Redirect to={{pathname: "/notfound/store", state: {from: props.location}}}/>;
        }
    }

    return <Route {...rest} render={render}/>;
}