import {call, put} from 'redux-saga/effects';
import axios, {AxiosResponse} from "axios";
import {commentsActions} from "./commentsSlice";
import * as commentsApis from "./comments.api";
import {notifyErrorResponseApi} from "../../core/errors";

const {setOrderComments} = commentsActions;
const {apiGetOrderComments} = commentsApis;

export function* getOrderCommentsSaga() {
    try {
        const {status, data}: AxiosResponse = yield call(apiGetOrderComments);
        if (status === 200) {
            yield put(setOrderComments(data.comments));
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}