import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../core/redux/store";

interface storeProductsSlice {
    loader_store_products: boolean,
    store_products: [],
    loader_store_products_finished: boolean,
    store_products_finished: [],
}

const initialState: storeProductsSlice = {
    loader_store_products: false,
    store_products: [],
    loader_store_products_finished: false,
    store_products_finished: [],
}

export const StoreProductsSlice = createSlice({
    name: 'storeProducts',
    initialState: initialState,
    reducers: {
        getStoreProducts: (state, action) => {
            const {payload} = action;
            if (payload !== 'finished') {
                state.loader_store_products = true;
            } else {
                state.loader_store_products_finished = true;
            }
        },
        setStoreProducts: (state, action) => {
            const {payload: {products, type}} = action;
            if (type !== 'finished') {
                return {...state, loader_store_products: false, store_products: products};
            } else {
                return {...state, loader_store_products_finished: false, store_products_finished: products};
            }
        },
    },
});

export const storeProductsActions = StoreProductsSlice.actions;

export const storeProductsReducer = StoreProductsSlice.reducer;

export const selectStoreProductsPage = (state: RootState) => state.storeProducts;
