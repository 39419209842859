import {Form, Image, Input, Rate} from "antd-mobile";
import {MapImageLocationStore} from "../Maps";

export const FormStore = ({data}: any) => {
    return (
        <Form initialValues={{...data, address: data.address?.text_address}}>
            <div style={{textAlign: 'center'}}>
                <Image src={data.image} width={64} height={64} fit='cover' style={{borderRadius: 32}}/>
                <div dir="ltr">
                    <Rate allowHalf value={data.score}/>
                    <div><small>تعداد نظرات <span>({data.count_score})</span></small></div>
                </div>
            </div>
            <Form.Item label='نام فروشگاه' name="title">
                <Input type="text" disabled/>
            </Form.Item>
            <Form.Item label='توضیحات فروشگاه' name="description">
                <Input type="text" disabled/>
            </Form.Item>
            {/*<Form.Item label='باز بودن فروشگاه' name="open" valuePropName="checked">
                <Switch disabled/>
            </Form.Item>*/}
            {/*<Form.Item label='ارسال رایگان' name="free_delivery" valuePropName="checked">
                <Switch disabled/>
            </Form.Item>
            <Form.Item label='حداقل مبلغ برای ارسال رایگان' name="min_price_for_free_delivery" valuePropName="checked">
                <Input type="number" disabled/>
            </Form.Item>*/}
            <Form.Item label='آدرس فروشگاه' name="address">
                <Input type="text" disabled/>
            </Form.Item>
            <div style={{width: '100%', height: 150}}>
                <MapImageLocationStore lat={data.address.lat} lng={data.address.lng}/>
            </div>
        </Form>
    )
}