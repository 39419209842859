import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../core/redux/store";

interface ordersSlice {
    length: number,
    orders: any,
    loading: {
        getOrders: boolean
    },
}

const initialState: ordersSlice = {
    length: 0,
    orders: [],
    loading: {
        getOrders: false,
    }
}

export const OrdersSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        actionGetOrders: (state, action) => {
            state.loading.getOrders = true;
        },
        actionSetOrders: (state, action) => {
            const {payload} = action;
            state.loading.getOrders = false;
            state.orders = payload.orders;
            state.length = payload.length || 0;
        },
    },
});

export const {actions: ordersActions, reducer: ordersReducer} = OrdersSlice;

export const selectOrders = (state: RootState) => state.orders;
