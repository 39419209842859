import {call, put} from 'redux-saga/effects';
import axios, {AxiosResponse} from "axios";
import {productsActions} from "./productsSlice";
import * as addProductApis from "./productAPI";
import {notifyErrorResponseApi} from "../../core/errors";
import {toast} from "react-toastify";

const {setProductCategories, setProductCategoryProducts, actionSetProduct, actionSetCreateProduct} = productsActions;
const {actionSetEditProduct} = productsActions;
const {apiGetProductCategories, apiGetProductCategoryProducts, apiGetProduct, apiCreateProductStore} = addProductApis;
const {apiEditProductStore} = addProductApis;

export function* getProductCategoriesSaga() {
    try {
        const {status, data}: AxiosResponse = yield call(apiGetProductCategories);
        console.log({data})
        if (status === 200) {
            yield put(setProductCategories(data.product_categories));
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}

export function* getProductCategoriesProductsSaga({payload}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiGetProductCategoryProducts, payload);
        if (status === 200) {
            yield put(setProductCategoryProducts(data.products));
        }
    } catch (e) {
        yield put(setProductCategoryProducts([]));
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}

export function* sagaGetProduct({payload}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiGetProduct, payload);
        if (status === 200) {
            yield put(actionSetProduct(data.product));
        }
    } catch (e) {
        yield put(actionSetProduct({}));
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}

export function* sagaCreateProduct({payload: {data: pData, history}}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiCreateProductStore, pData);
        if (status === 200) {
            toast.success(data.message);
            yield put(actionSetCreateProduct({}));
            history.push('/products');
        }
    } catch (e) {
        yield put(actionSetCreateProduct({}));
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}

export function* sagaEditProduct({payload: {data: pData,slug, history}}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiEditProductStore,slug, pData);
        console.log({data})
        if (status === 200) {
            toast.success(data.message);
            yield put(actionSetEditProduct({}));
            history.push('/products');
        }
    } catch (e) {
        yield put(actionSetEditProduct({}));
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}


export const sagaProducts = {
    getProductCategoriesSaga,
    getProductCategoriesProductsSaga,
    sagaGetProduct,
    sagaCreateProduct,
    sagaEditProduct
};
