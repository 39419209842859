import _ from "lodash";
import {toast} from "react-toastify";
import {AxiosError} from "axios";

export const notifyErrorResponseApi = (e: AxiosError) => {
    console.log(e.response ? e.response : e);
    if (e && e.response && e.response.data.errors) {
        _.forEach(e.response.data.errors, error => toast.error(error[0]));
    }
    if (e?.response?.data?.message) {
        toast.error(e.response.data.message);
    }
}