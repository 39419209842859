import {Route, Redirect, useHistory} from "react-router-dom";
import {useSelector} from "../../hooks";

export const AuthRoute = ({children, component: Component, ...rest}: any) => {
    const {isAuthorized} = useSelector(state => state.auth);
    const history: any = useHistory();
    const render = (props: any) => {
        if (!isAuthorized) {
            return Component ? <Component {...props}/> : children;
        } else {
            if (history.location.state && history.location.state.from && history.location.state.from.pathname) {
                return <Redirect to={history.location.state.from.pathname}/>;
            }
            return <Redirect to={{pathname: "/", state: {from: props.location}}}/>;
        }
    }

    return <Route {...rest} render={render}/>;
}