import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {persistor, store} from "./core/redux/store";
import {ConfigProvider} from "antd";
import faIR from 'antd/lib/locale/fa_IR';

import './core/assets/styles/index.less';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ConfigProvider direction="rtl" locale={faIR}>
                <App />
            </ConfigProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
