import {Layout, Menu} from "antd";
import React from "react";
import {AiOutlineLogout} from "react-icons/all";
import {useDispatch} from "../../hooks";
import {authActions} from "../../../modules/auth/authSlice";
import {Dialog} from "antd-mobile";

export const Sidebar = ({collapsed, setCollapsed}: any) => {
    const dispatch = useDispatch();
    const {loggedOut} = authActions;
    const exit = () => {
        setCollapsed(true);
        Dialog.confirm({
            content: 'میخواهید از حساب کاربری خود خارج شوید؟',
            cancelText: 'خیر',
            confirmText: 'بله',
            onConfirm: async () => {
                dispatch(loggedOut());
            },
        })

    }
    return (
        <Layout.Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
            onCollapse={() => setCollapsed((oldState: boolean) => !oldState)}
            style={{overflow: 'auto', height: '100vh', position: 'fixed', right: 0, zIndex: 1100}}
        >
            <Menu mode="inline">
                <Menu.Item key="exit" onClick={exit} icon={<AiOutlineLogout/>} style={{color: 'red'}}>
                    خروج از حساب کاربری
                </Menu.Item>
            </Menu>
        </Layout.Sider>
    )
}