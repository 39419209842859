import {call, put} from 'redux-saga/effects';
import axios, {AxiosResponse} from "axios";
import {storeProductsActions} from "./storeProductsSlice";
import * as storeProductsApis from "./storeProducts.api";
import {notifyErrorResponseApi} from "../../../core/errors";

const {setStoreProducts} = storeProductsActions;
const {apiGetStoreProducts} = storeProductsApis;

export function* getStoreProductsSaga({payload}: any) {
    try {
        const {status, data}: AxiosResponse = yield call(apiGetStoreProducts, payload);
        if (status === 200) {
            yield put(setStoreProducts({products: data.products, type: payload}));
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            notifyErrorResponseApi(e);
        }
    }
}