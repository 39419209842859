import {main} from "../../core/apis";
import {Apis} from "../../core/apis";

export const apiGetProductCategories = () => {
    return main.get(Apis.PRODUCT_CATEGORIES_GET);
}

export const apiGetProductCategoryProducts = (slug: string) => {
    return main.get(Apis.PRODUCT_CATEGORIES_GET + `/${slug}/` + Apis.PRODUCTS_GET);
}

export const apiGetProduct = (slug: string) => {
    return main.get(`products/${slug}`);
}

export const apiCreateProductStore = (data: any) => {
    return main.post(`storeProducts`, data);
}

export const apiEditProductStore = (slug: string, data: any) => {
    return main.put(`storeProducts/${slug}`, data);
}