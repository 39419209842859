import {Link} from "react-router-dom";
import React from "react";
import {useHistory} from "react-router";
import {useSelector, useDispatch} from "../../core/hooks";
import {authActions} from "./authSlice";
import {Col, Layout, Row} from "antd";
import {Button, Card, Form, Input} from "antd-mobile";
import {HiLogin} from "react-icons/all";

export const LoginActiveCode = () => {
    const {mobile, code, loader_otp_validation, timer} = useSelector(state => state.auth.form_login);
    const dispatch = useDispatch();
    const history = useHistory();
    const {validationActiveCodeLogin, changeValueFormLogin, getResendCode} = authActions;

    const handleSubmitForm = () => {
        dispatch(validationActiveCodeLogin({mobile, code, history}));
    }

    const handleChangeInputs = (number: any) => {
        dispatch(changeValueFormLogin({name: "code", value: number}));
    }

    const handleResendCode = () => {
        dispatch(getResendCode({mobile}));
    }

    return (
        <Layout.Content style={{display: 'flex', padding: 5}}>
            <Card
                title={<div style={{fontWeight: 'normal'}}>ورود به پنل فروشنده اُماتو</div>}
                extra={<HiLogin/>}
                style={{borderRadius: '16px', margin: 'auto', minWidth: 300, border: 'none'}}
                className="cm-card-login"
            >
                <Form onFinish={handleSubmitForm} initialValues={{mobile,code:''}}>
                    <div>
                        <Form.Item label="شماره موبایل" name="mobile">
                            <Input type="tel" disabled/>
                        </Form.Item>
                        <Form.Item label="کد فعال سازی" name='code'>
                            <Input type="number" value={code} onChange={handleChangeInputs}/>
                        </Form.Item>
                    </div>
                    <div style={{textAlign: 'center', marginTop: 8}}>
                        <Row>
                            <Col xs={12} style={{padding: '0 5px'}}>
                                <Button
                                    color='primary'
                                    size="mini"
                                    type="submit"
                                    block
                                    disabled={loader_otp_validation}
                                >
                                    ورود
                                </Button>
                            </Col>
                            <Col xs={12} style={{padding: '0 5px'}}>
                                <Button
                                    color='primary'
                                    block
                                    size="mini"
                                    type='button'
                                    disabled={loader_otp_validation || timer > 0}
                                    onClick={handleResendCode}
                                >
                                    <span>ارسال مجدد </span>
                                    <span>({timer})</span>
                                </Button>
                            </Col>
                            <Col xs={24}>
                                <Link to='/login/phone' style={{fontSize: 13}}>
                                    برای تغییر شماره موبایل کلیک کنید.
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Card>
        </Layout.Content>
    )
}