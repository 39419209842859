import {Tabs} from "antd-mobile";
import {ListOrders} from "./components";

export const OrdersPage = () => {
    return (
        <Tabs className='sticky-tab'>
            <Tabs.TabPane
                // title={<Badge content='5'><div>سفارشات جدید</div></Badge>}
                title="در انتظار پیک"
                key='new-order'
            >
                <ListOrders type="ok"/>
                {/*<InfiniteScrollOrders/>*/}
            </Tabs.TabPane>
            <Tabs.TabPane title="هماهنگی با پیک" key='send'>
                <ListOrders type="send"/>
            </Tabs.TabPane>
            <Tabs.TabPane title="تحویل داده شده" key='delivered'>
                <ListOrders type="delivered"/>
            </Tabs.TabPane>
        </Tabs>
    )
}