import {Swiper, SwiperSlide} from 'swiper/react';
import _ from "lodash";

export const SliderProductsOrders = (props) => {
    const {products, imgSize = {height: 60, width: 60}, showTitle = false} = props;
    return (
        <Swiper spaceBetween={10} slidesPerView={'auto'}>
            {_.map(products, (product,index) =>
                <SwiperSlide key={index} style={{width: 'auto'}}>
                    <div className="text-center">
                        <div>
                            <img
                                src={product.image}
                                style={{width: imgSize.width, height: imgSize.height}}
                                alt=""
                            />
                        </div>
                        {showTitle &&
                        <div>
                            <h6 className="font-12 m-0">{product.title}</h6>
                            <p className="font-12 m-0">{product.quantity} {product.unit}</p>
                            <p className="font-12 m-0">
                                <span>هر {product.unit}: </span>
                                <span>{product.price.toLocaleString()}</span>
                            </p>
                            <p className="font-12 m-0">
                                <span>تخفیف هر {product.unit} : </span>
                                <span>{product.discount.toLocaleString()}</span>
                            </p>
                            <p className="font-12 m-0">
                                <span>مجموع قیمت : </span>
                                <span>{product.total_price.toLocaleString()}</span>
                            </p>
                        </div>
                        }
                    </div>
                </SwiperSlide>
            )}
        </Swiper>
    )
}