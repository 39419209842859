import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../core/redux/store";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

interface AuthSlice {
    isAuthorized: boolean;
    loader_login: boolean;
    form_login: {
        loader_by_mobile: boolean,
        loader_otp_validation: boolean,
        mobile: string,
        code: string,
        timer: number,
        page: string,
    },
    profile: {
        id: number,
        last_name: string,
        first_name: string,
    }
    loading: {
        editStore: boolean
    }
}

const initialState: AuthSlice = {
    isAuthorized: false,
    loader_login: true,
    form_login: {
        loader_by_mobile: false,
        loader_otp_validation: false,
        mobile: '',
        code: '',
        timer: 0,
        page: 'BY_MOBILE',
    },
    profile: {
        id: 0,
        last_name: '',
        first_name: '',
    },
    loading: {
        editStore: false,
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        getProfile: (state) => {
            state.loader_login = true;
        },
        setProfile: (state, action) => {
            const {payload} = action;
            return {...state, isAuthorized: true, loader_login: false, profile: payload};
        },
        loggedOut: () => {
            return {...initialState, loader_login: false};
        },
        getActiveCodeLogin: (state, action) => {
            state.form_login.loader_by_mobile = true;
        },
        validationActiveCodeLogin: (state, action) => {
            state.form_login.loader_otp_validation = true;
        },
        loggedIn: (state, action) => {
            const {payload} = action;
            return {...state, isAuthorized: true, profile: payload, loader_login: false};
        },
        changeValueFormLogin: (state, action) => {
            const {name, value} = action.payload;
            state.form_login = {...state.form_login, [name]: value};
        },
        getResendCode: (state, action) => {
            state.form_login.loader_otp_validation = true;
        },
        actionGetEditStore: (state, action) => {
            state.loading.editStore = true;
        },
        actionSetEditStore: (state, action) => {
            state.loading.editStore = false;
            state.profile = action.payload;
        },
        actionChangeLoadingEditStore: (state, action) => {
            state.loading.editStore = action.payload;
        },
        actionChangeLoadingLogin: (state, action) => {
            state.loader_login = action.payload;
        },
    },
});

const authPersistConfig = {
    key: 'supplier-omato-auth',
    version: 1,
    storage: storage,
    whitelist: ['']
};

export const authActions = authSlice.actions;

export const authReducer = persistReducer(authPersistConfig, authSlice.reducer);

export const selectAuth = (state: RootState) => state.auth;
