import {useEffect} from "react";
import {Button, ErrorBlock, Image, List} from "antd-mobile";
import _ from "lodash";
import {Col, Row} from "antd";
import {useDispatch, useSelector} from "../../hooks";
import {storeProductsActions} from "../../../modules/products/storeProducts/storeProductsSlice";
import {LoadingPage} from "../Loader";
import {useHistory} from "react-router-dom";

export const ListStoreProducts = ({type = ""}: any) => {
    const finished = type === "finished";
    const {
        store_products,
        loader_store_products,
        loader_store_products_finished,
        store_products_finished
    } = useSelector(state => state.storeProducts);
    const dispatch = useDispatch();
    const {push} = useHistory();

    const products = !finished ? store_products : store_products_finished;
    const loader = !finished ? loader_store_products : loader_store_products_finished;

    const {getStoreProducts, setStoreProducts} = storeProductsActions;

    useEffect(() => {
        dispatch(getStoreProducts(type));
        return () => {
            dispatch(setStoreProducts({products, type}));
        }
        // eslint-disable-next-line
    }, []);

    const handleClickProduct = (slug: string) => {
        push(`/products/${slug}/manage/edit`);
    }
    return !loader ? (
        <>
            {products.length ?
                <List>
                    {_.map(products, (product: any, index) => (
                        <List.Item key={index}>
                            <div style={{fontWeight: 'normal', display: "flex", alignItems: 'center'}}>
                                <Image src={product.image} style={{borderRadius: 20}} fit='cover'
                                       width={40} height={40}/>
                                <span>{product.title}</span>
                            </div>
                            <Row justify="space-between">
                                <Col>دسته بندی :</Col>
                                <Col>{product.category_title}</Col>
                            </Row>
                            <Row justify="space-between">
                                <Col>قیمت :</Col>
                                <Col>
                                    <span>{product.price.toLocaleString()}</span>
                                    <span>تومان</span>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col>تخفیف :</Col>
                                <Col>
                                    <span>{product.discount.toLocaleString()}</span>
                                    <span>تومان</span>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col>تعداد موجود :</Col>
                                <Col>
                                    <span>{product.inventory}</span>
                                    <span>{product.unit}</span>
                                </Col>
                            </Row>
                            <div>
                                <Button
                                    onClick={() => handleClickProduct(product.slug)}
                                    color="warning"
                                    size="mini"
                                    block
                                >
                                    ویرایش
                                </Button>
                            </div>
                        </List.Item>
                    ))

                    }
                </List>
                :
                <ErrorBlock status='empty' title="404" description="محصولی یافت نشده."/>
            }
        </>
    ) : (<LoadingPage/>)
}