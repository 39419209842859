import {Form, Input} from "antd-mobile";

export const FormWallet = ({data}: any) => {
    return (
        <>
            <Form initialValues={data}>
                <Form.Item label='موجودی (تومان)' name="inventory">
                    <Input type="text" disabled/>
                </Form.Item>
            </Form>
        </>
    )
}