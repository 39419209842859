import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../core/redux/store";

interface ProductsSlice {
    loader_get_product_categories_login: boolean,
    product_categories: [],
    loader_get_products_login: boolean,
    products: [],
    product: {},
    loader_get_product: boolean,
    loading: {
        manageProduct: boolean,
    }
}

const initialState: ProductsSlice = {
    loader_get_product_categories_login: false,
    product_categories: [],
    loader_get_products_login: true,
    products: [],
    product: {},
    loader_get_product: true,
    loading: {
        manageProduct: false,
    }
}

export const productsSlice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
        getProductCategories: (state) => {
            state.loader_get_product_categories_login = true;
        },
        setProductCategories: (state, action) => {
            const {payload} = action;
            return {...state, loader_get_product_categories_login: false, product_categories: payload};
        },
        getProductCategoryProducts: (state, action) => {
            state.loader_get_products_login = true;
        },
        setProductCategoryProducts: (state, action) => {
            const {payload} = action;
            return {...state, loader_get_products_login: false, products: payload};
        },
        actionGetProduct: (state, action) => {
            state.loader_get_product = true;
        },
        actionSetProduct: (state, action) => {
            state.loader_get_product = false;
            state.product = action.payload;
        },
        actionGetCreateProduct: (state, action) => {
            state.loading.manageProduct = true;
        },
        actionSetCreateProduct: (state, action) => {
            state.loading.manageProduct = false;
        },
        actionGetEditProduct: (state, action) => {
            state.loading.manageProduct = true;
        },
        actionSetEditProduct: (state, action) => {
            state.loading.manageProduct = false;
        }
    },
});

export const {actions: productsActions, reducer: productsReducer} = productsSlice;
export const selectProducts = (state: RootState) => state.products;
