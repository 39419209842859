import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "./core/hooks";
import {Router} from "./core/Router";
import {authActions} from "./modules/auth/authSlice";
import {LoadingPage} from "./core/components/Loader";
import {ToastContainer} from "react-toastify";

function App() {

    const dispatch = useDispatch();
    const {loader_login} = useSelector(state => state.auth);
    const {getProfile} = authActions;

    useEffect(() => {
        dispatch(getProfile());
        // eslint-disable-next-line
    }, [dispatch]);

    return (
        <div id='app'>
            <ToastContainer rtl={true} position={'bottom-left'} autoClose={2000}/>
            {!loader_login ? <Router/> : <LoadingPage/>}
        </div>
    );
}

export default App;
