import {Switch, useRouteMatch} from "react-router";
import {Redirect, Route} from "react-router-dom";
import {useSelector} from "../hooks";
import {LoginActiveCode, LoginFormMobile} from "../../modules/auth";

export const AuthRouters = () => {
    const match = useRouteMatch();

    const {mobile} = useSelector(state => state.auth.form_login);

    return (
        <Switch>
            <Route path={`${match.path}/login`} component={LoginFormMobile}/>
            {mobile && <Route path={`${match.path}/active-code`} component={LoginActiveCode}/>}
            <Redirect to={`${match.url}/login`}/>
        </Switch>
    )
}