import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../core/redux/store";

interface commentsSlice {
    loader_get_comments: boolean,
    order_comments: [],
}

const initialState: commentsSlice = {
    loader_get_comments: false,
    order_comments: [],
}

export const CommentsSlice = createSlice({
    name: 'comments',
    initialState: initialState,
    reducers: {
        getOrderComments: (state) => {
            state.loader_get_comments = true;
        },
        setOrderComments: (state, action) => {
            const {payload} = action;
            return {...state, loader_get_comments: false, order_comments: payload};
        },
    },
});

export const commentsActions = CommentsSlice.actions;

export const commentsReducer = CommentsSlice.reducer;

export const selectComments = (state: RootState) => state.comments;
