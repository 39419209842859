import React from "react";
import {useSelector, useDispatch} from "../../core/hooks";
import {authActions} from "./authSlice";
import {useHistory} from "react-router";
import {Button, Card, Form, Input} from "antd-mobile";
import {Layout} from "antd";
import {HiLogin} from "react-icons/all";

export const LoginFormMobile = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {changeValueFormLogin, getActiveCodeLogin} = authActions;
    const {mobile, loader_by_mobile} = useSelector(state => state.auth.form_login);

    const handleChangeInputs = (number: any) => {
        dispatch(changeValueFormLogin({name: 'mobile', value: number}));
    }

    const handleSubmitForm = () => {
        dispatch(getActiveCodeLogin({mobile, history}));
    }

    return (
        <Layout.Content style={{display: 'flex',padding:5}}>
            <Card
                title={<div style={{fontWeight: 'normal'}}>ورود به پنل فروشنده اُماتو</div>}
                extra={<HiLogin/>}
                style={{borderRadius: '16px', margin: 'auto', minWidth: 300, border: 'none'}}
                className="cm-card-login"
            >
                <Form onFinish={handleSubmitForm}>
                    <div>
                        <Form.Item label="شماره موبایل" name="mobile">
                            <Input type="tel" value={mobile} onChange={handleChangeInputs}/>
                        </Form.Item>
                    </div>
                    <div style={{textAlign: 'center', marginTop: 8}}>
                        <Button
                            color='primary'
                            size="mini"
                            type="submit"
                            block
                            loading={loader_by_mobile}
                            disabled={loader_by_mobile}
                        >
                            دریافت رمز ورود
                        </Button>
                    </div>
                </Form>
            </Card>
        </Layout.Content>
    )
}