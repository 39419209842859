import {ErrorBlock, Image, List} from "antd-mobile";
import _ from "lodash";
import {useEffect} from "react";
import {productsActions} from "./productsSlice";
import {useDispatch, useSelector} from "../../core/hooks";
import {useHistory} from "react-router-dom";

export const ProductCategoriesPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {getProductCategories} = productsActions;
    const {product_categories, loader_get_product_categories_login}: any = useSelector(state => state.products);

    useEffect(() => {
        dispatch(getProductCategories());
        // eslint-disable-next-line
    }, []);

    const handleClickItemCategory = (slug: string) => {
        history.push(`/products/add/product-categories/${slug}/products`);
    }
    return loader_get_product_categories_login || product_categories.length ? (
        <List>
            {_.map(product_categories, category => (
                <List.Item
                    key={category.id}
                    prefix={<Image src={category.image} style={{borderRadius: 20}} fit='cover' width={40} height={40}/>}
                    onClick={() => handleClickItemCategory(category.slug)}
                >
                    {category.title}
                </List.Item>
            ))}
        </List>
    ) : (
        <ErrorBlock status='empty' title="404" description="دسته بندی یافت نشد."/>
    )
}